body {
  background-color: #063588;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.pageHeaderContainer {
  width:  95%;
  display: block;
  overflow: hidden;
  margin: 0 auto;
}
.pageHeader {
  text-align: center;
  top: 0px;
  left: 1px;
  height: 300px;
  background: transparent linear-gradient(101deg, #ffffff 0%, #f5f2f2 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
.logoContainer {
  float: left;
  width: 450px;
  height: 250px;
  margin-top: 30px;
}
.infoContainer {
  margin-top: 26px;
  margin-left: 75px;
  float: right;
  width: 450px;
  height: 250px;
  font-size: 36pt;
  line-height: 65px;
  color: white;
  font-weight: bold;
  opacity: 1;
  border-radius: 8px;
}
.dateContainer {
  width: 98%;
  height: 125px;
  background: #1072ba 0% 0% no-repeat padding-box;
  padding: 26px;
}
.smallInfo {
  height: 125px;
  background: #2aaae2 0% 0% no-repeat padding-box;
  float: right;
  width: 48%;
  margin-right: 2%;
  margin-top: 2%;
  padding: 15px;
  font-size: 50pt !important;
}
.parent {
  width: 100%;
  height: 1330px;
  border: 1px solid #AAA;
  overflow: hidden;
  padding-left: 10px;
}

.child {
  height: 100%;
  margin-right: -50px; /* Maximum width of scrollbar */
  padding-right: 50px; /* Maximum width of scrollbar */
  overflow-y: scroll;
  overflow-x: hidden;
}

.tableContainer{
  overflow:scroll;
  overflow-x: hidden;
  overflow-y: initial;
}
.journeyTable {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  line-height: 65px;
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 37px;
}

.journeyTable thead tr th {
  background-color: #2aaae2;
   /* border-radius: 4px; */
  color: white;
  font-weight: bold;
  border-bottom: 1px solid #000;
  padding: 5px 8px;

}

.journeyTable tbody tr td {
  background-color: lightgray;
  font-weight: bold;
  /* border-radius: 4px;  */
  color: black;
  padding: 5px 8px;

}

.journeyTable .kalkmis {
  background-color: #fdffb7;
}
.bottomImage {
  position:fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
  height: 290px;
  overflow: hidden;
  margin-bottom: 0px;
  margin-left: 50px;
}
.left{
  text-align: left;
}
.floatLeft{
  float: left;
}
.bottomImagepadding{
  padding-left: -100px;
}
.licensePlateColumn{
  /* font-size: 27px !Important; */
}
.busTypeColumn{
  width: 12px;
}
.nextJourney{
  animation: journey 1s ease-in-out infinite;
}

@keyframes journey {
  0% {
    background-color: lightgray;
  }
  50% {
    background-color: orange;
  }
  100% {
    background-color: orangered;
  }
}
